import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Layout from "../Components/Layout";
import MainTitle from "../common/MainTitle/MainTitle";
import Section from "../common/Section/Section";

const links = ["コース", "ワンコイン体験"];

const AboutRegistrationPage = props => {
  return (
    <>
      <Layout
        links={links}
        title="Enrollment"
        subTitle="届出方法"
        seoTitle="届出方法について"
        seoDescrption="狭山スイミングクラブの届出方法はこちらです。"
        seoKeywords="届出方法,所沢,狭山市,お申し込み"
      >
        <Section>
          <Container>
            <Row>
              <Col>
                <MainTitle title="入会手続き" subTitle="" left />
                <p>
                  毎月28日までに、手続きをしてください。練習は翌月から始めていただきます。
                </p>
                <p class="text-success">
                  1回目の練習日にカードをお渡しいたします。
                </p>
                <MainTitle title="用品など" left />
                <p>
                  水着・帽子・バックが指定になっております。入会時にはお買い求めいただきますようお願いいたします。
                  <br />
                  持ち物には必ずお名前をご記入下さい。帽子はおでこの部分にフルネームで書いてください。
                </p>
                <MainTitle title="月会費・手数料について" left />
                <p>
                  月会費は、前納制になっておりますので、月末（28日）までに翌月分をフロントにてお支払いいただきます。
                </p>
                <p className="text-danger">
                  曜日・コースを変更する場合は（月の途中ではできません）手数料550円かかります。
                </p>
                <MainTitle title="休会について" left />
                <p>
                  1ヶ月まとめてお休みをする場合は、前月の28日までに休会届を提出して下さい。
                  （電話受付可能）
                  <span className="text-danger">
                    月会費の代わりに在籍料1.100円（1か月分）いただきます。
                  </span>
                  休会届が提出されていないと、その月の出席が無くても休会扱いにはなりません。
                  （いかなる理由でも受付しておりません。）続けて休会する場合は、月が替わるごとに
                  休会届けを提出して下さい。（28日まで）
                </p>
                <MainTitle title="コース変更について" left />
                <p>
                  コースの変更は、月単位のみ受付ております。
                  （月の途中での変更はできません。）
                </p>
                <p className="text-danger">
                  <i>
                    変更手数料550円になります。お電話での受付はしておりません。
                  </i>
                </p>
                　 　　
                <MainTitle title="休館日について" left />
                <p>
                  毎月29.30.31日は休館日となります。スクールが祝祭日にあった場合でもいつも通り練習があります。
                  <br />
                  1月、8月は施設工事のため休館日が設けられる場合がございます。
                  <br />
                  休館日はトップに掲載されておりますので、ご参照ください。
                </p>
                <MainTitle title="その他" left />
                <p>
                  カードの紛失、破損の場合の再発行は手数料550円いただきます。
                  <br />
                  1月・8月は特別スケジュールになります。
                </p>
              </Col>
            </Row>
          </Container>
        </Section>
      </Layout>
    </>
  );
};

export default AboutRegistrationPage;
